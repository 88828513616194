<!-- =========================================================================================
	File Name: ItemGridView.vue
	Description: Item Component - Grid VIew
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <!-- <div class="item-grid-view vx-row match-height">
        <div class="vx-col" :class="gridColumnWidth" v-for="item in items" :key="item.objectID"> -->
            <vx-card class="grid-view-item mb-base overflow-hidden" >
                <template slot="no-body">
                  <div class="w-full">

                    <!-- ITEM IMAGE -->
                    <div class="item-img-container bg-white h-64 flex items-center justify-center mb-2">
                        <img :src="item.foto ? `${parametros.storage_cdn_url}/${item.foto}` : '#'" :alt="item.nome" class="grid-view-img px-4">
                    </div>
                    <div class="item-details ">
                      <!-- TITLE & DESCRIPTION -->
                      <div class="flex items-center justify-center ">
                          <h6 class="truncate font-semibold mb-1">{{ item.nome }}</h6>
                      </div>
                      <div class="flex items-center justify-center ">
                        <p class="item-description truncate text-sm">{{ item.apelido ? item.apelido : 'Sem Apelido' }}</p>
                      </div>
                    </div>
                  </div>
                    <!-- SLOT: ACTION BUTTONS -->
                    <slot name="action-buttons" />

                </template>
            </vx-card>
       <!--  </div>
    </div> -->
</template>

<script>
export default{
    props: {
        item: {
            type: Object,
            required: true
        },
        parametros: null
    },
    data() {
      return {
      }
    },
}
</script>

<style lang="scss" scoped>
.grid-view-item {
    .grid-view-img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        transition: .35s;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

        .grid-view-img{
            opacity: 0.9;
        }
    }
}
</style>
